
@font-face {
    font-family: "Poppins-Regular";
    src: url("../../assets/Font/Poppin/Poppins-Regular.ttf");
  }

.react-tabs__tab {
    font: "Poppins-Regular";
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.react-tabs__tab-panel--selected{
    font: "Poppins-Regular";
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    height:150px;
    color: rgba(255, 255, 255, 0.45);
}

.react-tabs__tab{
    margin-right:71px;
    margin-top:29px;
    margin-bottom:16px;
}

.style1 {
    font: "Poppins-Regular";
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.45);
}

.tester {
    width:100%;
    background-color:#000;
}

.border_bottom_line{
    border-bottom: solid 1px #404040;
    padding-bottom: 20px;
}

.margin_table{
    margin-top:5px;
    margin-bottom:5px;
}

.react-tabs__tab-panel--selected{
    display:inline-block;
}

.table_border1{
    border-top:1px solid #404040;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab--selected{
    background:#000;
    border-color:#000;
    color:white;
    
}

.react-tabs{
    border-bottom: 1px solid #404040;
    border-top:1px solid #404040;
    margin: 0 0 10px;
    padding: 0;
    margin-bottom:24px;
}

.Collapsible__contentOuter{
    border-top:1px solid #404040;
    margin: 0 0 10px;
    padding: 0;
    margin-top:24px;
}

/* .zh_lang {
color:red
} */

.tc_lang {
    color:green
}

.en_lang{
    color:#ffffff
}

.blue {
    color:blue;
}
.p_jurisdiction{
    color:#d91d22 ;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #404040
}

.entity_style {
    font-size: 12px;
    margin-bottom:24px;
    cursor:pointer;
    position:relative;
}


/* .Collapsible__trigger is-open{
    font-size: 12px;
    padding-top:24px;
    position:relative;

} */

.is-open::after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 4px solid #dbdbdb ;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    left:107%;
    top:39%;
    transform: rotate(0deg);
}

.Collapsible__trigger{
    position:relative;

}
.is-closed::after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 4px solid #dbdbdb ;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    left:107%;
    top:39%;
    transform: rotate(272deg);
}

.react-tabs__tab {
    position: relative;
  }
  .react-tabs__tab::after {
    /* initially display none */
    display: none;
    
    /* position the red arrow */
    content: '';
    position: absolute;
    top:40px;
    left: calc(50% - 4px);
    /* css triangle */
    width: 0;
    height: 0;
    border-top: 5px solid red;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  .react-tabs__tab--selected::after {
    /* display block for selected */
    display: block;
  }
  .react-tabs__tab--selected::after {
    transform: rotate(180deg);
  }
 


.is-open {
    font-size: 12px;
    cursor:pointer;
}

.complianceWrapper h5:after {
    content: '';
    max-height: 516px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    height: 9px;
    width: 10px;
    float: right;
    position: absolute;
    right: 67%;
    top: 50%;
    margin-top: -7.28px;
}