/* Font */

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/Font/Poppin/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/Font/Poppin/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Georgia";
  src: url("./assets/Font/Georgia/Georgia.ttf");
}

/* Smooth scroll */

html {
  scroll-behavior: smooth;
}

/* Global */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font: normal 16px "Poppins-Regular", sans-serif;
  line-height: 1.5;
  color: #000;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.625rem;
  padding: 4.5rem 0;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 14px;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

p {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  height: 100%;
  padding: 0 2rem;
}

.grid-container-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
  grid-column-gap: 2rem;
}

.grid-container-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 100%;
  grid-column-gap: 2rem;
}

.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
}

/* Navbar */

.navbar {
  height: 56px;
  /* fixed height */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 5;
}

.input1 {
  color:red;
}

.navbar .container {
  display: flex;
}

.navbar-brand {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-item {
  padding-left: 2vw;
  font-size: 14px;
}

.navbar-link {
  color: #fff;
}

.navbar.navbar-light .navbar-link {
  color: #05060f;
}
/* .navbar-link1{
  color: #fff;
} */

.navbar-link:hover {
  color: #e43b27;
}

/* Home */

.home {
  font-family: "Georgia", serif;
  color: #fff;
  background: black url('./assets/1.1.png') no-repeat scroll center / cover;
  /* height: 100vh; */
}

.home .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.wrapper {
  width: 68%;
  margin: 180px 0px;
}

.home h1 {
  margin: 1rem 0 1rem 0;
  font-family: Georgia;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
}

.home h2 {
  padding: 0;
  font-family: Georgia;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.home p {
  font-family: Georgia;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

/* Brands */

.brands {
  color: #fff;
  background: black url('./assets/2.png') no-repeat scroll center / cover;
  position: relative;
  overflow: hidden;
}

.brands h2 {
  position: absolute;
  font-family: "Georgia", sans-serif;
  color: #fff;
  padding: 0;
  margin-top: 4.5rem;
}

.brands .grid-container-4 {
  grid-gap: 0;
}

.brands .grid-container-5 {
  grid-gap: 0;
}

.brand {
  height: 100%;
  position: relative;
  z-index: 2;
  /* border-left: 1px solid rgba(255, 255, 255, 0.16); */
}

.brand:last-child {
  /* border-right: 1px solid rgba(255, 255, 255, 0.16); */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(0%);
  transition: all .5s ease;
  backdrop-filter: blur(60px);
  background: #ffffff19;
}

.brand-item {
  padding: 242px 10% 24px 10%;
  /* 2rem */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all .3s ease;
}

.brand:hover .brand-item {
  transform: translate(0, 0);
}

.brand:hover .overlay {
  opacity: 1;
  transform: translateY(0%);
}

.brand-item p {
  padding-top: 30px;
  padding-bottom: 24px;
  line-height: 1.75;
  flex: 1 0 0;
}

.brand-item a {
  display: block;
  color: #fff;
  margin-top: 1rem;
}

.brand-item a:hover {
  color: #e43b27;
}

/* Core Values */

.core-values h2 {
  font-family: "Georgia";
  text-align: center;
}

.core-values h3 {
  color: #333;
  margin-bottom: 2rem;
}

.core-values h4 {
  color: #666;
  margin-bottom: 1rem;
}

.core-values p {
  color: #999;
  font-size: 14px;
}

.outer-grid {
  grid-template-columns: 1.8fr 1.2fr;
}

.inner-grid-left {
  grid-template-columns: auto 1fr;
}

.inner-grid-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-content-top, .grid-content-bottom {
  grid-template-columns: auto 1fr;
}

.inner-grid-left .grid-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inner-grid-content {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-grid-right .grid-content h3 {
  margin-bottom: 1.25rem;
}

.inner-grid-right .grid-content p {
  font-size: 16px;
  line-height: 1.63
}

/* Global offices */

.global-offices h2 {
  font-family: "Georgia";
  text-align: center;
  padding-top: 104px;
  padding-bottom: 36px;
}

.global-offices h3 {
  text-align: center;
  padding-bottom: 24px;
}

.global-offices h5 {
  text-align: center;
}

.global-offices .map {
  text-align: center;
  margin-bottom: 7.25rem;
  position: relative;
  overflow: hidden;
  padding-top: 24px;
}

.arrow-down {
  border: solid #666666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 10px 0 35px 0;
  transform: rotate(45deg);
  text-align: center;
  position: absolute;
  left: 50%;
}

.hover-text:hover ~ .arrow-down {
  transform: rotate(225deg);
}

.hover-text {
  background: -webkit-linear-gradient(#666666, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  padding-bottom: 2em;
  transition: 0.5s;
  height: 160px;
}

.hover-text:hover {
  -webkit-text-fill-color: #666666;
  background: none;
  text-overflow: clip;
  white-space: normal;
  opacity: 1;
  width: auto;
  height: 255px;
}

.point {
  position: absolute;
}

.red-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(230, 28, 28, 1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.red-dot:hover {
  animation: blink 1s ease-in-out infinite none;
}

.red-dot-blink {
  animation: blink 1s ease-in-out infinite none;
}

.red-dot:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  opacity: 0.1;
  background-color: #e03323;
  border-radius: 50%;
  position: relative;
  top: -4.5px;
  left: -4.5px;
  cursor: pointer;
}

@keyframes blink {
  0% {
    box-shadow: 0 0 0 0 rgba(230, 28, 28, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(230, 20, 20, 0.1);
  }
}

.place:hover .tooltip, .red-dot:hover .tooltip {
  cursor: pointer;
  opacity: 1;
  width: 275px;
  white-space: break-spaces;
}

.place, .tooltip {
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: 0;
  transform: translate(-110%, -35%);
  pointer-events: none;
}

.tooltip {
  transform: translate(-50%, -115%);
  text-align: left;
  background-color: #fff;
  padding: 12px;
  color: #a3a3a3;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: all .3s ease;
  pointer-events: none;
  z-index: 1;
  width: 275px;
  white-space: break-spaces;
}

.tooltip:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 48.5%;
  top: 105%;
  transform: translateY(-50%);
}

.tooltip-wrap {
  overflow-wrap: break-word;
}

/* Footer */

footer {
  background-color: #000;
  color: #fff;
  padding: 42px 0 55px 0;
}

/* 1 */

footer .left {
  display: flex;
  margin-bottom: 4.5rem;
}

.about-us {
  margin-right: auto;
  max-width: 20%;
}

.about-us p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 1rem 0 1.15rem 0;
}

.social-media p, .contact-us p {
  font-size: 20px;
  margin-bottom: 14px;
}

.social-media ul, .contact-us ul {
  font-size: 14px;
  line-height: 1.57;
  color: rgba(255, 255, 255, 0.6);
}

.social-media li {
  display: flex;
  align-items: center;
}

.icon {
  margin-top: 2px;
  margin-right: 4px;
}

.social-media li, .contact-us li {
  margin-bottom: 10px;
}

.social-media a {
  color: rgba(255, 255, 255, 0.6);
}

/* 2 */

footer .logo {
  padding: 20px 0;
  text-align: center;
}

/* 3 */

.name, .detail, .website {
  font-size: 12px;
  font-weight: 300;
}

.name {
  margin-top: 24px;
}

.name-header {
  font-size: 14px;
}

.name:first-child {
  margin-top: 41px;
}

.detail {
  color: rgba(255, 255, 255, 0.45);
  margin-top: 14px;
}

.detail-highlight {
  color: rgba(255, 255, 255, 0.65)
}

.copyright {
  padding-bottom: 40px;
}

.website {
  margin-top: 18px;
}

.website a {
  color: #eb4d33;
}

.wei-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
}

.wei-mask img {
  width: 80%;
  margin-top: 30px;
}

@media only screen and (max-width: 780px) {
  /* Home */
  .home h1 {
    font-size: 2.625rem;
  }
  .home h2 {
    font-size: 16px;
  }
  .home p {
    font-size: 20px;
  }
  .wrapper {
    width: 100%;
  }
  /* Core Values */
  .outer-grid {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .inner-grid-left {
    grid-template-columns: 3fr 4fr;
  }
  .inner-grid-left figure, .inner-grid-left img {
    max-width: 100%;
    height: auto;
  }
  .grid-content-top, .grid-content-bottom {
    grid-template-columns: auto 1fr;
  }
  /* Our brands */
  .brand-item p, a {
    font-size: 12px;
  }
  /* Footer */
  footer .logos {
    grid-template-columns: repeat(2, 1fr);
  }
}



.react-tabs__tab {
  position: relative;
}

.react-tabs__tab::after {
  /* initially display none */
  display: none;
  
  /* position the red arrow */
  content: '';
  position: absolute;
  left: calc(50% - 4px);

  /* css triangle */
  width: 0;
  height: 0;
  border-top: 8px solid red;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.react-tabs__tab--selected::after {
  transform: rotate(45deg);
}

/* pageError */
.pageError .pageError-wrapper {
  margin-top: ;
  display: flex;
  justify-content: center;
  margin: 265px auto 450px;
}
.pageError .pageError-image-wrapper {

}
.pageError .pageError-image {
  max-width: 188px;
  height: auto;
}
.pageError .pageError-content {
  margin-left: 40px;
}
.pageError .pageError-text {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000018;
  padding: 0;
}
.pageError .pageError-link {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d91d22;
  cursor: pointer;
}